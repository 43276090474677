import React from 'react';

const SearchIcon = ({ fill }) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='transition-all duration-150'
    >
      <path
        d='M10.5417 19.9377C5.36254 19.9377 1.14587 15.721 1.14587 10.5418C1.14587 5.36266 5.36254 1.146 10.5417 1.146C15.7209 1.146 19.9375 5.36266 19.9375 10.5418C19.9375 15.721 15.7209 19.9377 10.5417 19.9377ZM10.5417 2.521C6.11421 2.521 2.52087 6.1235 2.52087 10.5418C2.52087 14.9602 6.11421 18.5627 10.5417 18.5627C14.9692 18.5627 18.5625 14.9602 18.5625 10.5418C18.5625 6.1235 14.9692 2.521 10.5417 2.521Z'
        fill={fill ? fill : '#5B6B79'}
      />
      <path
        d='M20.1667 20.8545C19.9926 20.8545 19.8184 20.7903 19.6809 20.6528L17.8476 18.8195C17.5817 18.5536 17.5817 18.1136 17.8476 17.8478C18.1134 17.582 18.5534 17.582 18.8192 17.8478L20.6526 19.6811C20.9184 19.947 20.9184 20.387 20.6526 20.6528C20.5151 20.7903 20.3409 20.8545 20.1667 20.8545Z'
        fill={fill ? fill : '#5B6B79'}
      />
    </svg>
  );
};

export default SearchIcon;
