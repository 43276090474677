import { lazy } from "react";
import { roles } from "../../utils/roleManager/roles";
import migrant_route from "./migrant_route";

const organized_work_route = [
  {
    path: "organized-work/:childId/:directionTypeCode",
    key: "asda",
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t, params) => {
        return {
          link: `organized-work/${params?.childId}/${params?.directionTypeCode}/`,
          title:
            params?.directionTypeCode === "P"
              ? t("header.text2")
              : t("header.text3"),
        };
      },
    },
    children: [
      {
        index: true,
        component: lazy(() =>
          import("../../components/organized_work_pages/OrganizedWorkList")
        ),
      },
      ...migrant_route,
    ],
  },
];

export default organized_work_route;
