import React from 'react';

const ArrowRight = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || '20'}
      height={props?.height || '20'}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.91586 13.7563C8.12002 13.9604 8.44919 13.9604 8.65336 13.7563L12.1159 10.2938C12.2784 10.1313 12.2784 9.86876 12.1159 9.70626L8.65336 6.24376C8.44919 6.0396 8.12002 6.0396 7.91586 6.24376C7.71169 6.44793 7.71169 6.7771 7.91586 6.98126L10.9325 10.0021L7.91169 13.0229C7.71169 13.2229 7.71169 13.5563 7.91586 13.7563Z'
        fill={props?.fill ? props?.fill : '#5B6B79'}
      />
    </svg>
  );
};

export default ArrowRight;
