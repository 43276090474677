const DEFAULT_GEO_DATA = { geodata: {}, geodata_loading: true };
const INITIAL_STATE = {
  country_geodata: DEFAULT_GEO_DATA,
  region_geodata: DEFAULT_GEO_DATA,
  district_geodata: DEFAULT_GEO_DATA,
  neighborhood_geodata: DEFAULT_GEO_DATA,
};

const reducers_geodata = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_GEODATA_COUNTRY":
      return {
        ...state,
        country_geodata: action?.payload,
      };
    case "SET_GEODATA_REGION":
      return {
        ...state,
        region_geodata: action?.payload,
      };
    case "SET_GEODATA_DISTRICT":
      return {
        ...state,
        district_geodata: action?.payload,
      };
    case "SET_GEODATA_NEIGHBORHOOD":
      return {
        ...state,
        neighborhood_geodata: action?.payload,
      };
    default:
      return state;
  }
};
export default reducers_geodata;
