import React from 'react';

const CrossingBorderSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.75 14.5834L15 13.125H13.4506C12.5773 13.125 12.1407 13.125 11.7723 12.9401C11.4038 12.7553 11.1615 12.4147 10.6771 11.7334L8.21177 8.26658C7.72737 7.58539 7.48517 7.24479 7.11668 7.0599C6.74819 6.87502 6.31155 6.87502 5.43827 6.87502H5M13.75 5.41669L15 6.87502H13.4506C12.5773 6.87502 12.1407 6.87502 11.7723 7.0599C11.4038 7.24479 11.1615 7.58539 10.6771 8.26658M5 13.125H5.43827C6.31155 13.125 6.74819 13.125 7.11668 12.9401C7.48517 12.7553 7.72737 12.4147 8.21177 11.7334'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.08325 9.99998C2.08325 6.26803 2.08325 4.40205 3.24262 3.24268C4.40199 2.08331 6.26797 2.08331 9.99992 2.08331C13.7318 2.08331 15.5978 2.08331 16.7573 3.24268C17.9166 4.40205 17.9166 6.26803 17.9166 9.99998C17.9166 13.7319 17.9166 15.5979 16.7573 16.7573C15.5978 17.9166 13.7318 17.9166 9.99992 17.9166C6.26797 17.9166 4.40199 17.9166 3.24262 16.7573C2.08325 15.5979 2.08325 13.7319 2.08325 9.99998Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
      />
    </svg>
  );
};

export default CrossingBorderSidebarIcon;
