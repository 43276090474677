import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';

const statistic_route = [
  {
    path: '/main-statistic/:directionTypeCode',
    component: lazy(() =>
      import('../../components/statistic_pages/MainStatistic')
    ),

    handle: {
      breadcrumb: (t, params) => {
        return {
          link: `organizedwork-statistic/${params?.directionTypeCode}/`,
          title: t('sidebar.security'),
        };
      },
    },
  },
  {
    path: '/organizedwork-statistic/:directionTypeCode',
    component: lazy(() =>
      import('../../components/statistic_pages/OrganizedWorkStatistic')
    ),
    handle: {
      breadcrumb: (t, params) => {
        return {
          link: `organizedwork-statistic/${params?.directionTypeCode}/`,
          title: t('home3.organized-work'),
        };
      },
    },
  },
  {
    path: 'immigrant-statistics',
    children: [
      {
        index: true,
        component: lazy(() =>
          import('../../components/main_pages/ImmigrantStatistics')
        ),
        handle: {
          title: 'Immigrant statistics',
          roles: [roles.allow_any],
          links: [{ link: '/', title: 'inspectors.home' }],
          active: 'other_t.depersonalized_information',
        },
      },
    ],
  },
  {
    path: 'statistics-immigrant/:childId',
    children: [
      {
        index: true,
        component: lazy(() =>
          import('../../components/immigragion_pages/ImmigrantMain')
        ),
        handle: {
          title: 'Statistics immigrant',
          roles: [roles.allow_any],
        },
      },
    ],
  },
];

export default statistic_route;
