import React from 'react';

const SpecialCategorySidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.0599 14.4971L11.6889 3.48233C10.9313 2.17256 9.06883 2.17256 8.31125 3.48233L1.94024 14.4971C1.17191 15.8254 2.11368 17.5 3.62907 17.5H16.3711C17.8865 17.5 18.8282 15.8254 18.0599 14.4971Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M10.4166 14.5834V10.8334C10.4166 10.6033 10.23 10.4167 9.99992 10.4167H9.58325'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 7.91669H10.0075'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SpecialCategorySidebarIcon;
