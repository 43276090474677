export const objectToQueryString = (obj = {}) => {
	var queryString = [];
	for (var key in obj) {
		queryString.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
	}
	return queryString.join('&');
};

export const queryStringToObject = (queryString = '') => {
	var pairs = queryString.split('&');
	var obj = {};
	pairs.forEach(pair => {
		var keyValue = pair.split('=');
		if (keyValue[0]) {
			obj[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1] || '');
		}
	});
	return obj;
};
export const createQueryString = queryObj => {
	const searchParams = new URLSearchParams(queryObj);
	return searchParams.toString();
};

export const filterNoEmptyValues = obj => {
	return Object.entries(obj).reduce((newObj, [key, value]) => {
		if (value !== '') {
			newObj[key] = value;
		}
		return newObj;
	}, {});
};

export const isValidEmail = email => {
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailPattern.test(email);
};

export const formatNumber = num => {
	// Convert the number to a string
	let str = num?.toString();
	// Insert spaces every 3 digits from the right
	return str?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatNumber2 = num => {
	// Check if num is defined and is a number
	if (num === undefined || typeof num !== 'number') {
		return '';
	}
	// Convert the number to a string
	let str = num?.toString();
	// Insert spaces every 3 digits from the right
	return str?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
export const roundUpToNearestWithZeros = num => {
	const length = num.toString().length;
	const multiplier = Math.pow(10, length - 1);
	return formatNumber2(Math.ceil(num / multiplier) * multiplier);
};

export const capitalizeFirstLetter = input => {
	if (!input) return '';
	return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const convertToFormattedDate = dateString => {
	const date = new Date(dateString);

	return date?.toString();
};

export const areAllValuesNull = obj => {
	return Object.values(obj).every(value => value === null || value === undefined || typeof value === 'function');
};

export const getInitialParam = (location, param) => {
	return new URLSearchParams(location.search).get(param) || '';
};

export const capitalizeWords = text => {
	return text
		?.split(' ')
		?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		?.join(' ');
};

export const isAgeLessThan18 = birth_date => {
	const birthDate = new Date(birth_date);
	const today = new Date();

	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDiff = today.getMonth() - birthDate.getMonth();
	const dayDiff = today.getDate() - birthDate.getDate();

	// Adjust age if birth date has not occurred yet this year
	if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
		age--;
	}

	return age < 18;
};

export const calculateExpressionChart = m => {
	var lg_m = parseInt(Math.log10(m));
	var result = Math.pow(10, lg_m + 1);
	if (m < result / 5) result = parseInt(result / 5) + 1;
	if (m < result / 3) result = parseInt(result / 3) + 1;
	if (m < (2 * result) / 3) result = parseInt((2 * result) / 3) + 1;
	return result;
};

export const formatDateToDDMMYYYY = dateString => {
	const parseDate = str => {
		if (str?.includes('.')) {
			// Format: dd.mm.yyyy
			const [day, month, year] = str?.split('.');
			return new Date(`${year}-${month}-${day}`);
		} else if (str?.includes('-')) {
			// Format: yyyy-mm-dd
			return new Date(str);
		}
		throw new Error('Unsupported date format');
	};

	const date = parseDate(dateString ? dateString : '');

	const day = String(date?.getDate())?.padStart(2, '0');
	const month = String(date?.getMonth() + 1)?.padStart(2, '0');
	const year = date?.getFullYear();

	return `${day}.${month}.${year}`;
};

export const formatDate = isoDate => {
	if (!isoDate) return null;

	const date = new Date(isoDate);

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};
