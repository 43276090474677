import React from 'react';

const ImmigrantsSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5135 16.6667H15.922C16.8802 16.6667 17.6423 16.2301 18.3267 15.6197C20.0651 14.0689 15.9785 12.5 14.5834 12.5M12.9167 4.224C13.106 4.18646 13.3025 4.16669 13.5041 4.16669C15.0207 4.16669 16.2501 5.28598 16.2501 6.66669C16.2501 8.0474 15.0207 9.16669 13.5041 9.16669C13.3025 9.16669 13.106 9.14694 12.9167 9.10935'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M3.73443 13.426C2.75195 13.9525 0.175949 15.0276 1.7449 16.3728C2.51133 17.03 3.36493 17.5 4.4381 17.5H10.5619C11.6351 17.5 12.4887 17.03 13.2551 16.3728C14.8241 15.0276 12.2481 13.9525 11.2656 13.426C8.96167 12.1913 6.03833 12.1913 3.73443 13.426Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
      />
      <path
        d='M10.8334 6.25002C10.8334 8.09097 9.341 9.58335 7.50008 9.58335C5.65913 9.58335 4.16675 8.09097 4.16675 6.25002C4.16675 4.40907 5.65913 2.91669 7.50008 2.91669C9.341 2.91669 10.8334 4.40907 10.8334 6.25002Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
      />
    </svg>
  );
};

export default ImmigrantsSidebarIcon;
