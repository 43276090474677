import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';
import migrant_route from './migrant_route';

const separate_category_route = [
  {
    path: 'separate-category/:childId/:directionTypeCode',
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t, params) => {
        return {
          link: `separate-category/${params?.childId}/${params?.directionTypeCode}/`,
          title:
            params?.directionTypeCode === 'P'
              ? t('other_t.important_category_list')
              : t('other_t.important_category_list_simple'),
        };
      },
    },
    children: [
      {
        index: true,
        component: lazy(() =>
          import('../../components/separate_category_pages/SeparateCategory')
        ),
      },
      ...migrant_route,
    ],
  },
];

export default separate_category_route;
