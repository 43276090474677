import axios from 'axios';
import { API_URL } from './api';
import { removeToken } from './tokenStorge';

axios.interceptors.request.use(
	config => config,
	e => Promise.reject(e)
);
axios.interceptors.response.use(
	response => response,
	e => {
		const error = e?.toJSON();
		if (error) {
			if (error?.status === 401) {
				removeToken();
				localStorage.removeItem('refresh');
				window.location.href = '/login';
				return;
			}
			if (error?.status === 403) {
				removeToken();
				localStorage.removeItem('refresh');
				return;
			}
			if (error?.message === 'Network Error') {
				// toast.warning(
				//   localStorage.getItem("i18nextLng") === "uz"
				//     ? "Internetingiz uzildi!"
				//     : "Нет подключения к Интернету!"
				// );
				return;
			}
		}
		return Promise.reject(e);
	}
);
const Axios = (Api_Url, access_token) => {
	const token = access_token ? access_token : localStorage.getItem('token');
	const defaultOptions = {
		baseURL: Api_Url ? Api_Url : API_URL,
		headers: token
			? {
					Authorization: `Bearer ${token}`,
					lan: localStorage.getItem('i18nextLng') || 'uz',
			  }
			: { lan: localStorage.getItem('i18nextLng') || 'uz' },
		params: { lan: localStorage.getItem('i18nextLng') || 'uz' },
	};

	return {
		get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
		post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
		put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
		delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
	};
};
export default Axios;
