import { lazy } from "react";
import { roles } from "../../utils/roleManager/roles";
import migrant_route from "./migrant_route";
const emigrants_route = [
  {
    path: "emigrants/:childId/:directionTypeCode",
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t, params) => {
        return {
          link: `emigrants/${params?.childId}/${params?.directionTypeCode}/`,
          title:
            params?.directionTypeCode === "P"
              ? `${t("header.text2")} (${
                  params?.childId === "5"
                    ? t("home2.less-90days")
                    : t("sidebar.day_90")
                })`
              : `${t("home.abroad_uzb")} (${
                  params?.childId === "6"
                    ? t("home2.less-90days")
                    : t("sidebar.day_90")
                })`,
        };
      },
      
    },
    children: [
      {
        index: true,
        component: lazy(() =>
          import("../../components/main_pages/EmigrantsList")
        ),
      },
      ...migrant_route,
    ],
  },
];

export default emigrants_route;
