import { lazy } from "react";
import { roles } from "../../utils/roleManager/roles";

const search_route = [
  {
    path: "/search/results",
    children: [
      {
        component: lazy(() =>
          import("../../components/main_pages/SearchResults")
        ),
        index: true,
        handle: {
          title: "Search Results",
          roles: [roles.allow_any],
        },
      },
    ],
  },
];

export default search_route;
