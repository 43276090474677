import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./i18n";
import LoadingMain from "../sections/utilsSections/LoadingMain";
// import { find, get } from "lodash";

const WebProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((s) => s.main);
  useEffect(() => {
    getCountrysGeoData();
    getRegionsGeoData();
    getDistrictsGeoData();
    // getGeoJsonToJson();
  }, []);
  const getCountrysGeoData = () => {
    dispatch({
      type: "SET_GEODATA_COUNTRY",
      payload: { geodata: [], geodata_loading: true },
    });
    fetch("/geojson/country_geo.json", {
      cache: "force-cache",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "SET_GEODATA_COUNTRY",
          payload: { geodata: data, geodata_loading: false },
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_GEODATA_COUNTRY",
          payload: { geodata: [], geodata_loading: false },
        });
      });
  };
  const getRegionsGeoData = () => {
    dispatch({
      type: "SET_GEODATA_REGION",
      payload: { geodata: [], geodata_loading: true },
    });
    fetch("/geojson/region_geo.json", {
      cache: "force-cache",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "SET_GEODATA_REGION",
          payload: { geodata: data, geodata_loading: false },
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_GEODATA_REGION",
          payload: { geodata: [], geodata_loading: false },
        });
      });
  };
  const getDistrictsGeoData = () => {
    dispatch({
      type: "SET_GEODATA_DISTRICT",
      payload: { geodata: [], geodata_loading: true },
    });
    fetch("/geojson/district_geo.json", {
      cache: "force-cache",
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: "SET_GEODATA_DISTRICT",
          payload: { geodata: data, geodata_loading: false },
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_GEODATA_DISTRICT",
          payload: { geodata: [], geodata_loading: false },
        });
      });
  };

  /* convert geojsonl file to json file*/
  const getGeoJsonToJson = () => {
    fetch("/geojson/regions.geojsonl")
      // fetch("/geojson/districts.geojsonl")
      .then((response) => response.text())
      .then((regionsText) => {
        const parseGeoJson = (text) =>
          text
            .split("\n")
            .filter((line) => line.trim())
            .map((line) => JSON.parse(line));
        const regionData = parseGeoJson(regionsText);
        let r_data = [];
        regionData.forEach((item) => {
          r_data.push({
            type: "Feature",
            properties: {
              ADMIN: item?.properties?.nomi_lot,
              region_soato: item?.properties?.region,
              // district_soato: item?.properties?.district,
            },
            geometry: item.geometry,
          });
        });
        console.log("getRegionGeoData:", r_data);
        // downloadJsonFile(ttt);
      })
      .catch(() => {});
  };

  const downloadJsonFile = (jsonData) => {
    const fileName = "data.json";
    const jsonString = JSON.stringify(jsonData);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  };
  return (
    <>
      <div>
        {children}
        {loading && <LoadingMain />}
      </div>
    </>
  );
};
export default WebProvider;
