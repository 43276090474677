import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';
import migrant_route from './migrant_route';

const filter_question_route = [
  {
    path: 'filter-question/:childId/:directionTypeCode/:score',
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t, params) => {
        return {
          link: `filter-question/${params?.childId}/${params?.directionTypeCode}/${params?.score}/`,
          title: t('home.filter_q'),
        };
      },
    },
    children: [
      {
        index: true,
        component: lazy(() =>
          import('../../components/main_pages/FilterQuestionList')
        ),
      },
      ...migrant_route,
    ],
  },
];

export default filter_question_route;
