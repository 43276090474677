import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const Breadcrumb2 = ({ breadcrumbs = [] }) => {
  const { t } = useTranslation();
  const params = useParams();
  const active_breadcrumbs = breadcrumbs?.filter((o) => o !== null);
  let full_path_name = "";

  return (
    <>
      <div className="">
        <div className="flex w-full mb-2">
          <div className="">
            <div className="flex flex-wrap items-center gap-1  ">
              {active_breadcrumbs?.length > 0
                ? active_breadcrumbs?.map((item, index) => {
                    const breadcrumb = item(t, params);
                    full_path_name += breadcrumb?.link;
                    if (index !== active_breadcrumbs.length - 1) {
                      return (
                        <div
                          className="flex justify-center items-center gap-1"
                          key={index}
                        >
                          <div
                            key={index + "edsxrads"}
                            className=" text-sm font-normal"
                          >
                            <Link
                              to={full_path_name || "/"}
                              className="!text-shuttleGray"
                            >
                              {breadcrumb?.title}
                            </Link>
                          </div>
                          <div className="flex justify-center items-center text-shuttleGray">
                            /
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="text-blueWaterloo  text-sm font-normal">
                          {breadcrumb?.title}
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Breadcrumb2;
