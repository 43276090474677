import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  matchPath,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ArrowRight from "../../assets/svg/ArrowRight";
import LogOut from "../../assets/svg/LogOut";
import AccessSidebarIcon from "../../assets/svg/sidebar/AccessSidebarIcon";
import AgeeSidebarIcon from "../../assets/svg/sidebar/AgeeSidebarIcon";
import CrossingBorderSidebarIcon from "../../assets/svg/sidebar/CrossingBorderSidebarIcon";
import DashboardSidebarIcon from "../../assets/svg/sidebar/DashboardSidebarIcon";
import ImmigrantsSidebarIcon from "../../assets/svg/sidebar/ImmigrantsSidebarIcon";
import MigrantApplicationsSidebarIcon from "../../assets/svg/sidebar/MigrantApplicationsSidebarIcon";
import OrganizedWorkSidebarIcon from "../../assets/svg/sidebar/OrganizedWorkSidebarIcon";
import PoliceSidebarIcon from "../../assets/svg/sidebar/PoliceSidebarIcon";
import SpecialCategorySidebarIcon from "../../assets/svg/sidebar/SpecialCategorySidebarIcon";
import { MAIN_PAGE } from "../../utils/constants";
import {
  checkPermissionUser,
  permissions,
} from "../../utils/roleManager/permissions";
import { removeToken } from "../../utils/tokenStorge";

const SideBar = ({ setOpenSection, openSection }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const { response } = useSelector((s) => s.main);
  const [openModal, setOpenModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const isLinkActive = (link) => {
    if (!link || link?.length === 0) return false;

    let is_active_link = false;
    link.forEach((l) => {
      if (matchPath(l, location.pathname)) {
        is_active_link = true;
      }
      const nestedPattern = `${l}/*`;
      if (matchPath(nestedPattern, location.pathname)) {
        is_active_link = true;
      }
    });
    return is_active_link;
  };

  const sidebarItems = [
    {
      label: t("sidebar.home"),
      permissions: [permissions.allow_any],
      link: ["/main-statistic", "/organizedwork-statistic"],
      icon: <DashboardSidebarIcon />,
      iconactive: <DashboardSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("sidebar.security"),
          permissions: [permissions.allow_any],
          link: [MAIN_PAGE, "/main-statistic/P"],
        },
        {
          label: t("sidebar.organized-work"),
          permissions: [permissions.allow_any],
          link: ["/organizedwork-statistic/M", "/organizedwork-statistic/P"],
          child: true,
        },
        {
          label: t("other_t.depersonalized_information"),
          permissions: [permissions.allow_any],
          // link: "/immigrant-statistics",
          link: ["#"],
          disable_link: true,
        },
      ],
    },
    // {
    //   label: t("sidebar.info"),
    //   // link: "/emigrants/4/M",
    //   link: "/emigrants/1/M",
    //   icon: <CrossingBorderSidebarIcon />,
    //   iconactive: <CrossingBorderSidebarIcon fill={"#4680FF"} />,
    // },
    {
      label: t("home.abroad_uzb"),
      permissions: [permissions.allow_any],
      link: ["/emigrants"],
      icon: <CrossingBorderSidebarIcon />,
      iconactive: <CrossingBorderSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: `${t("home2.less-90days")}`,
          permissions: [permissions.allow_any],
          link: ["/emigrants/6/M", "/emigrants/5/P"],
        },
        {
          label: `${t("sidebar.day_90")}`,
          permissions: [permissions.allow_any],
          link: ["/emigrants/1/M", "/emigrants/3/P"],
        },
        // {
        //   label: t("sidebar.statistics/2"),
        //   link: "/emigrants/0/P",
        // },
        // {
        //   label: `${t("sidebar.statistics/3")} (${t("sidebar.day_30")})`,
        //   link: "/emigrants/2/P",
        // },
        // {
        //   label: `${t("sidebar.statistics/3")} (${t("sidebar.day_90")})`,
        //   link: "/emigrants/3/P",
        // },
      ],
    },
    {
      label: t("sidebar.immigrant"),
      permissions: [permissions.allow_any],
      link: ["/statistics-immigrant/0"],
      // disable_link: true,
      icon: <ImmigrantsSidebarIcon />,
      iconactive: <ImmigrantsSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("sidebar.illegal_immigrant"),
          permissions: [permissions.allow_any],
          link: ["/applications/women-in-distress"],
          disable_link: true,
        },
      ],
      // childs: [
      //   {
      //     label: t("immigrant.foreigners"),
      //     // link: "/statistics-immigrant/0",
      //     link: "#",
      //     disable_link: true,
      //   },
      //   {
      //     label: t("immigrant.long_term_foreigners"),
      //     // link: "/statistics-immigrant/1",
      //     link: "#",
      //     disable_link: true,
      //   },
      //   {
      //     label: t("immigrant.foreigners_who_left"),
      //     // link: "/statistics-immigrant/3",
      //     link: "#",
      //     disable_link: true,
      //   },
      //   {
      //     label: t("immigrant.foreigners_stayed_long_term"),
      //     // link: "/statistics-immigrant/4",
      //     link: "#",
      //     disable_link: true,
      //   },
      // ],
    },
    {
      label: t("sidebar.organized_work"),
      permissions: [permissions.allow_any],
      link: ["/organized-work/0/M", "/organized-work/0/P"],
      icon: <OrganizedWorkSidebarIcon />,
      iconactive: <OrganizedWorkSidebarIcon fill={"#4680FF"} />,
      // childs: [
      //   {
      //     label: t('other_t.citizens_abroad'),
      //     link: '/organized-work/0/M',
      //   },
      //   {
      //     label: t('other_t.returned_citizens'),
      //     link: '/organized-work/0/P',
      //   },
      // ],
    },
    // {
    //   label: "Аналитика по пересечению границы",
    //   link: "#",
    //   icon: <DocIcon />,
    //   iconactive: <DocIcon fill={"#4680FF"} />,
    //   childs: [
    //     {
    //       label: "Граждане за рубежом",
    //       link: "/bordercrossing/citizens-abroad",
    //     },
    //     {
    //       label: "Граждане за рубежом (более 90 дней)",
    //       link: "/bordercrossing/citizens-abroaddays",
    //     },
    //     {
    //       label: "Вернувшиеся граждане",
    //       link: "/bordercrossing/returned-citizens",
    //     },
    //     // {
    //     // 	label: 'Иностранные граждане в стране',
    //     // 	link: '/bordercrossing/citizens-country',
    //     // },
    //   ],
    // },
    // {
    // 	label: 'Иммиграция',
    // 	link: ['#'],
    // 	icon: <DocIcon />,
    // 	iconactive: <DocIcon fill={'#4680FF'} />,
    // 	childs: [
    // 		{
    // 			label: 'Кетганлар',
    // 			link: '/immigration/to-leave',
    // 		},
    // 		{
    // 			label: 'Келганлар',
    // 			link: '/immigration/to-come',
    // 		},
    // 	],
    // },
    // {
    // 	label: 'Эмиргация',
    // 	link: ['#'],
    // 	icon: <DocIcon />,
    // 	iconactive: <DocIcon fill={'#4680FF'} />,
    // 	childs: [
    // 		{
    // 			label: 'Чиққанлар',
    // 			link: '/emigration/to-leave',
    // 		},
    // 		{
    // 			label: 'Кирганлар',
    // 			link: '/emigration/to-come',
    // 		},
    // 	],
    // },
    {
      label: t("sidebar.reports"),
      permissions: [permissions.allow_any],
      link: ["/reports"],
      icon: <OrganizedWorkSidebarIcon />,
      iconactive: <OrganizedWorkSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("home.abroad_uzb"),
          permissions: [permissions.allow_any],
          link: ["/reports/abroad-citizen"],
        },
        {
          label: t("home.abroad_uzb_back"),
          permissions: [permissions.allow_any],
          link: ["/reports/abroad-backcitizen"],
        },
        {
          label: t("other_t.stateless_persons_abroad"),
          permissions: [permissions.allow_any],
          link: ["/reports/reports-abroad-persons"],
          disable_link: false,
        },
        {
          label: t("sidebar.child33"),
          permissions: [permissions.allow_any],
          link: ["/reports/reports-guardians"],
          disable_link: true,
        },
        {
          label: t("sidebar.child44"),
          permissions: [permissions.allow_any],
          link: ["#"],
          disable_link: true,
        },
        {
          label: t("sidebar.child55"),
          permissions: [permissions.allow_any],
          link: ["#"],
          disable_link: true,
        },
        {
          label: t("sidebar.child66"),
          permissions: [permissions.allow_any],
          link: ["#"],
          disable_link: true,
        },
      ],
    },
    {
      label: t("sidebar.message"),
      permissions: [permissions.allow_any],
      link: ["/applications"],
      icon: <MigrantApplicationsSidebarIcon />,
      iconactive: <MigrantApplicationsSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("other_t.women_in_distress"),
          permissions: [permissions.allow_any],
          link: ["/applications/women-in-distress"],
          disable_link: true,
        },
      ],
    },
    {
      label: t("home.filter_q"),
      permissions: [permissions.view_filter_survey],
      link: [
        "/filter-question/2/P/1",
        "/filter-question/2/P/2",
        "/filter-question/2/P/3",
      ],
      icon: <CrossingBorderSidebarIcon />,
      iconactive: <CrossingBorderSidebarIcon fill={"#4680FF"} />,
    },
    {
      label: t("sidebar.separate_category"),
      permissions: [permissions.view_individual_category],
      link: ["separate-category"],
      icon: <SpecialCategorySidebarIcon />,
      iconactive: <SpecialCategorySidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("sidebar.separate_category/0"),
          permissions: [permissions.view_individual_category],
          link: ["/separate-category/0/P"],
        },
        {
          label: t("sidebar.separate_category/2"),
          permissions: [permissions.view_individual_category],
          link: ["/separate-category/4/M"],
        },
      ],
    },
    {
      label: t("sidebar.agee"),
      permissions: [permissions.allow_any],
      link: ["/minors/0/M"],
      icon: <AgeeSidebarIcon />,
      iconactive: <AgeeSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("other_t.foreign_citizens_minors"),
          permissions: [permissions.allow_any],
          link: ["/minors/0/M/main"],
        },
        {
          label: t("other_t.unaccompanied_minors"),
          permissions: [permissions.allow_any],
          link: ["/minors/0/M/unaccompanied"],
        },
        {
          label: t("sidebar.is_has_unaccompanied"),
          permissions: [permissions.allow_any],
          link: ["/minors/0/M/is-has-unaccompanied"],
        },
      ],
    },
    {
      label: t("sidebar.inspectors"),
      permissions: [permissions.allow_any],
      link: ["/inspectors"],
      icon: <PoliceSidebarIcon />,
      iconactive: <PoliceSidebarIcon fill={"#4680FF"} />,
    },
    {
      label: t("sidebar.accesses"),
      permissions: [permissions.allow_any],
      link: ["/accesses"],
      icon: <AccessSidebarIcon />,
      iconactive: <AccessSidebarIcon fill={"#4680FF"} />,
      childs: [
        {
          label: t("sidebar.organizations"),
          permissions: [permissions.allow_any],
          link: ["/accesses/organizations"],
        },
        {
          label: t("sidebar.employees"),
          permissions: [permissions.allow_any],
          link: ["/accesses/employees"],
        },
      ],
    },
  ];

  const variants = {
    open: { width: 270, opacity: 1 },
    closed: { width: 65, opacity: 1 },
  };

  const toggleSection = (section) => {
    setOpenSection((prev) => (prev === section ? null : section));
  };
  const toggleTooltip = (section) => {
    setOpenTooltip((prev) => (prev === section ? null : section));
  };

  useEffect(() => {
    const sectionIndex = sidebarItems
      ?.filter((o) => checkPermissionUser(o.permissions || []))
      ?.findIndex((item) => {
        return isLinkActive(item?.link);
      });
    if (sectionIndex !== -1) {
      setOpenSection(sectionIndex);
    }
  }, [location.pathname]);

  const logOut = () => {
    removeToken();
    localStorage.removeItem("refresh");
    dispatch({ type: "SET_USER", payload: {} });
    navigate("/login");
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!sidebarRef?.current.contains(event?.target) && response) {
        toggleSection(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [response, sidebarRef?.current]);

  return (
    <>
      {checkPermissionUser([permissions.allow_any])}
      <motion.div
        initial={response ? "closed" : "open"}
        animate={response ? "closed" : "open"}
        variants={variants}
        className={`  bg-white custom-scrollbar  scrol sticky inset-x-0 top-0 p-[8px]  overflow-x-scroll ${
          response ? "md:p-[8px]" : "md:p-[8px]"
        } flex flex-col justify-between h-screen border-r border-r-gallery`}
      >
        <div className="flex flex-col gap-4">
          {response ? (
            <Link to={MAIN_PAGE}>
              <img
                src="/images/logo-response.svg"
                alt="logo"
                className="h-[45px] max-h-[45px] w-[207px] mt-[12px]"
              />
            </Link>
          ) : (
            <Link to={MAIN_PAGE}>
              <img
                src="/images/logo.svg"
                alt="logo"
                className="h-[45px] max-h-[45px] mt-[12px] w-[207px]"
              />
            </Link>
          )}
          <div ref={sidebarRef} className="flex flex-col ">
            {sidebarItems
              .filter((o) => checkPermissionUser(o.permissions || []))
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item?.disable_link ? (
                    <div>
                      <div
                        className={` cursor-default rounded-lg opacity-50 ${
                          openSection === index
                            ? "bg-[#EDF3FF]"
                            : "hover:bg-wilfSand"
                        } ${
                          response
                            ? "py-[14.08px] px-[18.08px] flex justify-center"
                            : "py-[14.08px] px-[18.08px]"
                        }`}
                      >
                        <div
                          className={`flex items-center justify-between gap-2 `}
                        >
                          <div className="flex justify-center items-center gap-2">
                            <div>
                              {openSection === index
                                ? item.iconactive
                                : item.icon}
                            </div>
                            <p
                              className={`text-[15px] font-normal ${"text-black"}  ${
                                response ? "hidden" : "block"
                              }`}
                            >
                              {item.label}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : // ) : item?.link[0] === '#' ? (
                  item?.childs?.length ? (
                    <div
                      onClick={() => {
                        toggleSection(index);
                      }}
                    >
                      <div
                        onMouseEnter={() => {
                          if (response) {
                            toggleSection(index);
                          }
                        }}
                        className={`cursor-pointer rounded-lg ${
                          openSection === index
                            ? "bg-[#EDF3FF]"
                            : "hover:bg-wilfSand"
                        } ${
                          response
                            ? "py-[14.08px] px-[18.08px] flex justify-center"
                            : "py-[14.08px] px-[18.08px]"
                        }`}
                      >
                        <div
                          className={`flex items-center justify-between gap-2 `}
                        >
                          <div className="flex justify-center items-center gap-2">
                            <div>
                              {openSection === index
                                ? item.iconactive
                                : item.icon}
                            </div>
                            <p
                              className={`text-[15px] font-medium ${
                                openSection === index
                                  ? "text-royalBlue"
                                  : "text-shuttleGray"
                              }  ${response ? "hidden" : "block"}`}
                            >
                              {item.label}
                            </p>
                          </div>
                          {openSection === index ? (
                            <ArrowRight
                              fill={"#4680FF"}
                              className={`rotate-90 duration-250 transition-all ease-linear min-w-[20px] max-w-[20px] ${
                                response ? "hidden" : "inline-block"
                              }`}
                            />
                          ) : (
                            <ArrowRight
                              className={`rotate-270 duration-250 transition-all ease-linear min-w-[20px] max-w-[20px] ${
                                response ? "hidden" : "inline-block"
                              }`}
                            />
                          )}
                        </div>
                      </div>

                      <div
                        className={`overflow-hidden transition-max-height  rounded-md px-2 ease-in-out  ${
                          openSection === index
                            ? "max-h-max max-w-[250px] bg-white     rounded-lg"
                            : "max-h-0"
                        }  ${
                          response
                            ? `!fixed z-[9999]  ml-[60px] !h-[250px]  border-[#EFEFEF]  shadow-menuShadow       text-white   overflow-y-auto bg-blue dark:bg-blueBack ${
                                item?.type === "top"
                                  ? "mt-[-212px]"
                                  : "mt-[-56px]"
                              }`
                            : "bg-white"
                        }`}
                      >
                        <div className="w-full flex flex-col ">
                          {item?.childs
                            ?.filter((o) =>
                              checkPermissionUser(o.permissions || [])
                            )
                            ?.map((ch, ch_index) => {
                              return (
                                <div
                                  className=" w-full flex flex-col gap-[10px] mt-[10px] last:mb-[10px]"
                                  key={ch_index}
                                >
                                  {ch?.disable_link === true ? (
                                    <div className="flex items-center pl-[5px] gap-[10px] justify-start">
                                      {ch?.child ? (
                                        <span className="pl-8 flex">
                                          {response ? null : (
                                            <div className="max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center">
                                              <div className="border-[#B9BEC7] bg-white border-[2px] w-[5px] h-[5px]"></div>
                                            </div>
                                          )}
                                          <span
                                            className={` select-none ${
                                              response ? "" : ""
                                            }`}
                                          >
                                            {ch?.label}
                                          </span>
                                        </span>
                                      ) : (
                                        <>
                                          {response ? null : (
                                            <div className="max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center">
                                              <div className="bg-[#B9BEC7] rounded-full w-1 h-1"></div>
                                            </div>
                                          )}
                                          <span
                                            className={` text-[#B9BEC7] select-none ${
                                              response ? "" : ""
                                            }`}
                                          >
                                            {ch?.label}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <NavLink
                                      end
                                      to={ch.link[0]}
                                      // onClick={() => {
                                      // 	toggleSection(index);
                                      // }}
                                      className={
                                        "flex  items-center pl-[5px] gap-[10px]  justify-start"
                                      }
                                    >
                                      {ch?.child ? (
                                        <span className="pl-8 flex">
                                          {response ? null : (
                                            <div className="max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center">
                                              <div className="border-[#B9BEC7] bg-white border-[2px] w-[5px] h-[5px]"></div>
                                            </div>
                                          )}
                                          <span
                                            className={`text-[15px] font-medium select-none ${
                                              isLinkActive(ch.link)
                                                ? "text-[#4680FF]"
                                                : ""
                                            } ${response ? "" : ""}`}
                                          >
                                            {ch?.label}
                                          </span>
                                        </span>
                                      ) : (
                                        <>
                                          {response ? null : (
                                            <div className="max-w-6 max-h-6 min-w-6 min-h-6 flex items-center justify-center">
                                              <div className="bg-[#B9BEC7] rounded-full w-1 h-1"></div>
                                            </div>
                                          )}
                                          <span
                                            className={`text-[15px] font-medium select-none ${
                                              isLinkActive(ch.link)
                                                ? "text-[#4680FF]"
                                                : ""
                                            } ${response ? "" : ""}`}
                                          >
                                            {ch?.label}
                                          </span>
                                        </>
                                      )}
                                    </NavLink>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <NavLink
                        end
                        key={index}
                        onMouseEnter={() => {
                          if (response) {
                            toggleSection(null);
                            toggleTooltip(index);
                          }
                        }}
                        onMouseLeave={() => {
                          if (response) {
                            toggleTooltip(null);
                          }
                        }}
                        to={item.link[0]}
                        className={`relative cursor-pointer p-4 hover:bg-wilfSand rounded-lg flex items-center gap-2 ${
                          isLinkActive(item.link) ? "bg-wilfSand" : ""
                        }  ${response ? " py-2 flex justify-center" : "p-4"}`}
                        onClick={() => {
                          toggleSection(null);
                        }}
                      >
                        <div>
                          {isLinkActive(item.link)
                            ? item.iconactive
                            : item.icon}
                        </div>
                        <p
                          className={`text-[15px] font-medium ${
                            isLinkActive(item.link)
                              ? "text-[#4680FF]"
                              : "text-shuttleGray"
                          } ${response ? "hidden" : "block"}`}
                        >
                          {item.label}
                        </p>
                      </NavLink>
                      {index === openTooltip ? (
                        <div className=" z-[9999] fixed shadow-menuShadow rounded-md px-2 py-2 ease-in-out bg-white ml-[67px] mt-[-40px]">
                          {item.label}
                          <div className="absolute top-1/2 -left-2 -translate-y-1/2 w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[6px] border-r-white"></div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
        <div>
          <div
            className={`bottom-0 bg-white w-full flex cursor-pointer  hover:bg-wilfSand rounded-lg items-center gap-2 ${
              response
                ? "  py-[14.08px] flex justify-center"
                : "px-[18.08px] py-[14.08px]"
            }`}
            onClick={() => setOpenModal(true)}
          >
            <img src="/images/logout.svg" alt="logout" />
            <div
              className={`text-shuttleGray text-[15px] font-medium leading-[20px] ${
                response ? "hidden" : "block"
              }`}
            >
              {t("sidebar.exit")}
            </div>
          </div>
          {openModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg p-6  w-[500px]   flex flex-col  justify-center gap-6">
                <div className="flex justify-center">
                  <LogOut
                    fill="#4680FF"
                    width={"64"}
                    height="64"
                    className=" text-center"
                  />
                </div>
                <div>
                  <h2 className=" text-lg font-medium text-center text-[#1D2630]">
                    {" "}
                    {t("sidebar.log_out_desc")}
                  </h2>
                  <div className="text-[#1D2630]  text-base text-center">
                    {" "}
                    {t("sidebar.log_out_sub_desc")}
                  </div>
                </div>
                <div className="flex justify-center gap-2">
                  <button
                    className="px-4 py-2 text-[#1D2630] border-[#E0E3EA] hover:border-royalBlue hover:text-royalBlue border rounded-lg h-[46px] w-full"
                    onClick={() => setOpenModal(false)}
                  >
                    {t("sidebar.no")}
                  </button>
                  <button
                    className="px-4 py-2  text-white bg-[#4680FF] rounded-lg h-[46px] w-full  hover:opacity-80 "
                    onClick={() => {
                      logOut();
                    }}
                  >
                    {t("sidebar.yes")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default SideBar;
