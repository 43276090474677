import store from '../reducers/store';

export const permissions = {
	allow_any: 'allow_any',
	view_bordercross: 'view_bordercross',
	view_borderpoint: 'view_borderpoint',
	view_conviction: 'view_conviction',
	view_convictioncheck: 'view_convictioncheck',
	view_convictionrecord: 'view_convictionrecord',
	view_country: 'view_country',
	view_diploma: 'view_diploma',
	view_disabledperson: 'view_disabledperson',
	view_district: 'view_district',
	view_divorce: 'view_divorce',
	view_doctor: 'view_doctor',
	view_document: 'view_document',
	view_dope: 'view_dope',
	view_education: 'view_education',
	view_experience: 'view_experience',
	view_grddata: 'view_grddata',
	view_helpbook: 'view_helpbook',
	view_institution: 'view_institution',
	view_ironbook: 'view_ironbook',
	view_marriage: 'view_marriage',
	view_migrant: 'view_migrant',
	view_nostrifikatsiya: 'view_nostrifikatsiya',
	view_permission: 'view_permission',
	view_pinpp: 'view_pinpp',
	view_position: 'view_position',
	view_psyche: 'view_psyche',
	view_region: 'view_region',
	view_schoolgraduate: 'view_schoolgraduate',
	view_transport: 'view_transport',
	view_travelpurpose: 'view_travelpurpose',
	view_travelpurposeguide: 'view_travelpurposeguide',
	view_universitymigrant: 'view_universitymigrant',
	view_womenbook: 'view_womenbook',
	view_workandcameback: 'view_workandcameback',
	view_workandcamebackcountry: 'view_workandcamebackcountry',
	add_anketa: 'add_anketa',
	change_anketa: 'change_anketa',
	delete_anketa: 'delete_anketa',
	view_anketa: 'view_anketa',
	add_step: 'add_step',
	change_step: 'change_step',
	delete_step: 'delete_step',
	view_step: 'view_step',
	add_question: 'add_question',
	change_question: 'change_question',
	delete_question: 'delete_question',
	view_question: 'view_question',
	add_passing: 'add_passing',
	change_passing: 'change_passing',
	delete_passing: 'delete_passing',
	view_passing: 'view_passing',
	add_item: 'add_item',
	change_item: 'change_item',
	delete_item: 'delete_item',
	view_item: 'view_item',
	add_organization: 'add_organization',
	change_organization: 'change_organization',
	delete_organization: 'delete_organization',
	view_organization: 'view_organization',
	add_permission: 'add_permission',
	change_permission: 'change_permission',
	delete_permission: 'delete_permission',
	view_permission: 'view_permission',
	add_user: 'add_user',
	change_user: 'change_user',
	delete_user: 'delete_user',
	view_user: 'view_user',
	add_to_role_user: 'add_to_role_user',
	add_role: 'add_role',
	change_role: 'change_role',
	delete_role: 'delete_role',
	view_role: 'view_role',
	gender_information: 'gender_information',
	previous_fish: 'previous_fish',
	view_relative: 'view_relative',
	view_aliment: 'view_aliment',
	can_view_address: 'can_view_address',
	view_filter_survey: 'view_filter_survey',
	view_individual_category: 'view_individual_category',
};
export const checkPermissionUser = (allow_permissions = []) => {
	const reducers_state = store.getState();
	if (allow_permissions.includes(permissions.allow_any)) {
		return true;
	}
	const user_permissions = reducers_state?.main?.user?.permissions?.map(item => item.key) || [];
	const set_permissions = new Set(user_permissions);
	let allow_user = false;
	for (const item of allow_permissions) {
		if (set_permissions.has(item)) {
			allow_user = true;
		}
	}
	return allow_user;
};
