import { Dropdown } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '../../assets/svg/MenuIcon';
import SearchIcon from '../../assets/svg/SearchIcon';
const Header = () => {
  const { i18n, t } = useTranslation();
  const tabsRef = useRef([]);
  const { response } = useSelector((s) => s.main);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
  }, [activeTabIndex, location, tabUnderlineWidth, tabUnderlineLeft]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [hovered, setHovered] = useState({
    hovered1: false,
    hovered2: false,
    hovered3: false,
  });
  return (
    <div
      style={{
        width: `calc(${response ? '100% - 65px' : '100% - 270px'})`,
      }}
      className={` px-[20px] fixed py-3 h-[56px] mx-auto border-b bg-white border-b-gallery duration-250 transition-all ease-linear z-[9999] 
	${response ? 'max-w-[1935px]' : 'max-w-[1730px]'}
	flex justify-between `}
    >
      <div className='flex gap-[15px]'>
        <button
          onClick={() => {
            dispatch({ type: 'SET_RESPONSE', payload: !response });
          }}
          className='cursor-pointer w-[36px] flex justify-center items-center h-[36px] rounded-lg bg-alabaster 
          border hover:border-royalBlue hover:bg-[#EDF3FF]  hover:text-royalBlue
          '
          onMouseEnter={() =>
            setHovered((pV) => ({
              ...pV,
              hovered1: true,
            }))
          }
          onMouseLeave={() =>
            setHovered((pV) => ({
              ...pV,
              hovered1: false,
            }))
          }
        >
          <MenuIcon fill={hovered?.hovered1 ? '#4680FF' : '#5B6B79'} />
        </button>
        <div
          className='cursor-pointer w-[36px] flex justify-center items-center h-[36px] rounded-lg bg-alabaster 
          border hover:border-royalBlue hover:bg-[#EDF3FF] hover:text-royalBlue duration-150'
          onClick={() => {
            navigate('/search');
          }}
          onMouseEnter={() =>
            setHovered((pV) => ({
              ...pV,
              hovered2: true,
            }))
          }
          onMouseLeave={() =>
            setHovered((pV) => ({
              ...pV,
              hovered2: false,
            }))
          }
        >
          <SearchIcon fill={hovered?.hovered2 ? '#4680FF' : '#5B6B79'} />
        </div>
        {/* <form className=' border-[#FFFFFF] border-[1px]  hover:border-[1px] hover:border-[#EFEFEF] cursor-pointer rounded-lg '>
					<input
						type='text'
						className='py-[10px] pr-[10px] pl-[40px] w-full  border-1 focus:border-[1px] hover:border-[1px] border-[#FFFF] hover:border-[] cursor-pointer focus:border-[#EFEFEF] rounded-lg  '
						style={{
							background: 'url(/images/search.svg)',
							backgroundRepeat: 'no-repeat',
							backgroundPositionY: 'center',
							backgroundPositionX: '10px',
						}}
					/>
				</form> */}
      </div>
      <div className='flex gap-[10px]'>
        <Dropdown
          className='flex justify-center z-20 bg-alabaster outline-none'
          arrowIcon={false}
          renderTrigger={({ isOpen }) => (
            <div
              className={`cursor-pointer w-[36px] flex justify-center items-center h-[36px] rounded-lg bg-alabaster 
          border hover:border-royalBlue hover:bg-[#EDF3FF]  hover:text-royalBlue ${
            isOpen ? 'bg-[#4680FF]' : 'bg-alabaster hover:bg-[#4680FF]'
          }`}
            >
              {i18n.language === 'ru' ? (
                <img
                  src='/images/rus.svg'
                  className='w-[24px] h-[20px]'
                  alt=''
                />
              ) : (
                <img
                  src='/images/uzb.svg'
                  className='w-[24px] h-[20px]'
                  alt=''
                />
              )}
            </div>
          )}
        >
          <Dropdown.Item
            className='px-1'
            onClick={() => handleLanguageChange('uz')}
          >
            <img className='w-6 h-4' src='/images/uzb.svg' alt='' />
          </Dropdown.Item>
          <Dropdown.Item
            className='px-1'
            onClick={() => handleLanguageChange('ru')}
          >
            <img src='/images/rus.svg' alt='' />
          </Dropdown.Item>
        </Dropdown>
        {/* <button className='cursor-pointer  h-[44px] w-[44px] flex justify-center items-center rounded-lg bg-alabaster  dark:bg-shuttleGray'>
					<Switcher />
				</button> */}
        {/* <div className="cursor-pointer relative hidden  w-[44px] h-[44px] flex  justify-center items-center">
          <div className="flex justify-center items-center bg-royalBlue text-white rounded-full absolute  text-[12px] top-[6px] right-[6px] w-4 h-4">
            9
          </div>
          <Notification />
        </div> */}
        <button className='w-[36px] h-[36px] rounded-full bg-[#E9F0FF] text-royalBlue'>
          U
        </button>
      </div>
    </div>
  );
};

export default Header;
