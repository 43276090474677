import React from 'react';

const MigrantApplicationsSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4836 5.84625C12.4836 5.84625 12.9003 6.26292 13.317 7.09625C13.317 7.09625 14.6405 5.01292 15.817 4.59625'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.32912 1.68438C6.24706 1.59623 4.63851 1.83608 4.63851 1.83608C3.62281 1.90871 1.6763 2.47814 1.67631 5.80371C1.67633 9.10101 1.65478 13.166 1.67631 14.7865C1.67631 15.7766 2.28933 18.086 4.41113 18.2098C6.99016 18.3603 11.6357 18.3923 13.7671 18.2098C14.3377 18.1776 16.2373 17.7297 16.4777 15.6629C16.7268 13.5218 16.6772 12.0338 16.6772 11.6797'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3333 5.84623C18.3333 8.14742 16.466 10.0129 14.1626 10.0129C11.8592 10.0129 9.99194 8.14742 9.99194 5.84623C9.99194 3.54505 11.8592 1.67957 14.1626 1.67957C16.466 1.67957 18.3333 3.54505 18.3333 5.84623Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M5.81714 10.8464H9.15045'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M5.81714 14.1797H12.4838'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default MigrantApplicationsSidebarIcon;
