const DEFAULT_GEO_DATA = { data: {}, data_loading: true };
const INITIAL_STATE = {
  country_statistic: DEFAULT_GEO_DATA,
  region_statistic: DEFAULT_GEO_DATA,
};

const reducers_statistics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_STATISTIC_COUNTRY":
      return {
        ...state,
        country_statistic: action?.payload,
      };
    case "SET_STATISTIC_REGION":
      return {
        ...state,
        region_statistic: action?.payload,
      };
    default:
      return state;
  }
};
export default reducers_statistics;
