import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';

const accesses_route = [
	{
		path: 'accesses',
		children: [
			{
				path: 'organizations',
				handle: {
					breadcrumb: t => {
						return {
							link: 'accesses/organizations/',
							title: t('sidebar.organizations'),
						};
					},
					roles: [roles.allow_any],
				},

				children: [
					{
						index: true,
						component: lazy(() => import('../../components/accesses_pages/Accesses')),
						handle: {
							roles: [roles.allow_any],
						},
					},
					{
						path: ':id',
						handle: {
							breadcrumb: t => {
								return {
									link: 'accesses/organizations/:id/',
									title: t('sidebar.given_organizations'),
								};
							},
							roles: [roles.allow_any],
						},
						children: [
							{
								index: true,
								component: lazy(() => import('../../components/accesses_pages/AccessesItem')),
								handle: {
									roles: [roles.allow_any],
								},
							},
						],
					},
				],
			},
			{
				path: 'employees',
				handle: {
					breadcrumb: t => {
						return {
							link: 'accesses/employees/',
							title: t('sidebar.employees'),
						};
					},
					roles: [roles.allow_any],
				},
				children: [
					{
						index: true,
						component: lazy(() => import('../../components/accesses_pages/AccessesEmployeesList')),
						handle: {
							roles: [roles.allow_any],
						},
					},
					{
						path: 'add',
						handle: {
							breadcrumb: t => {
								return {
									link: 'accesses/employees/add/',
									title: t('sidebar.add_employee'),
								};
							},
							roles: [roles.allow_any],
						},
						component: lazy(() => import('../../components/accesses_pages/AccessesEmployees')),
					},
					{
						path: ':id',
						component: lazy(() => import('../../components/accesses_pages/AccessesEmployeesListItem')),
						handle: {
							breadcrumb: t => {
								return {
									link: 'accesses/employees/:id/',
									title: t('sidebar.given_employees'),
								};
							},
							roles: [roles.allow_any],
						},
					},
				],
			},
		],
	},
];

export default accesses_route;
