import { lazy } from "react";
import { roles } from "../../utils/roleManager/roles";

const applications_route = [
  {
    path: "/applications",
    children: [
      {
        path: "women-in-distress",
        children: [
          {
            index: true,
            component: lazy(() =>
              import(
                "../../components/migrant_applications_pages/WomenInDistress"
              )
            ),
            handle: {
              title: "Women in Distress",
              roles: [roles.allow_any],
            },
          },
        ],
      },
    ],
  },
];

export default applications_route;
