import { lazy } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../sections/layoutSections/Layout";
import { roles } from "../utils/roleManager/roles";
import accesses_route from "./route_block/accesses_route";
import applications_route from "./route_block/applications_route";
import emigrants_route from "./route_block/emigrants_route";
import filter_question_route from "./route_block/filter_question_route";
import inspectors_route from "./route_block/inspectors_route";
// import migrant_route from './route_block/migrant_route';
import minors_route from "./route_block/minors_route";
import organized_work_route from "./route_block/organized_work_route";
import reports_route from "./route_block/reports_route";
import search_route from "./route_block/search_route";
import separate_category_route from "./route_block/separate_category_route";
import statistic_route from "./route_block/statistic_route";

export const routes = [
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t) => {
        return {
          link: "/",
          title: t("home.title"),
        };
      },
    },
    children: [
      {
        index: true,
        component: lazy(() => import("../components/main_pages/MainPage")),
        handle: {
          roles: [roles.allow_any],
        },
      },
      // ...migrant_route,
      ...statistic_route,
      ...emigrants_route,
      ...organized_work_route,
      ...separate_category_route,
      ...filter_question_route,
      ...minors_route,
      ...reports_route,
      ...accesses_route,
      ...applications_route,
      ...inspectors_route,
      ...search_route,
    ],
  },
  {
    path: "search",
    component: lazy(() => import("../components/main_pages/Search")),
    handle: {
      title: "Search",
      roles: [roles.allow_any],
    },
  },

  {
    path: "login",
    component: lazy(() => import("../components/main_pages/auth/Login")),
    handle: {
      title: "Login",
      roles: [roles.allow_any],
    },
  },
  {
    path: "auth-callback",
    component: lazy(() => import("../components/main_pages/auth/CallBack")),
    handle: {
      title: "Login",
      roles: [roles.allow_any],
    },
  },
  {
    path: "user-not-found",
    component: lazy(() => import("../components/main_pages/auth/NoAcces")),
    handle: {
      title: "No Acces",
      roles: [roles.allow_any],
    },
  },
  {
    path: "*",
    component: lazy(() => import("../components/main_pages/auth/NotFount")),
    handle: {
      title: "404 | Not Fount",
      roles: [roles.allow_any],
    },
  },
];
