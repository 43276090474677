import React from 'react';

const DashboardSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.08325 9.99996C2.08325 6.26802 2.08325 4.40205 3.24262 3.24268C4.40199 2.08331 6.26797 2.08331 9.99992 2.08331C13.7318 2.08331 15.5978 2.08331 16.7573 3.24268C17.9166 4.40205 17.9166 6.26802 17.9166 9.99996C17.9166 13.732 17.9166 15.5979 16.7573 16.7573C15.5978 17.9166 13.7318 17.9166 9.99992 17.9166C6.26797 17.9166 4.40199 17.9166 3.24262 16.7573C2.08325 15.5979 2.08325 13.732 2.08325 9.99996Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
      />
      <path
        d='M2.08325 7.5H17.9166'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M5.83301 5H5.84049'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1665 5H9.174'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1666 14.1667C14.1666 11.8655 12.3011 10 9.99992 10C7.69874 10 5.83325 11.8655 5.83325 14.1667'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M10.5891 12.7441L9.41064 13.9226'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DashboardSidebarIcon;
