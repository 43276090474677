import { uniqBy } from 'lodash';

const INITIAL_STATE = {
	loading: false,
	user: {},
	auth_modal: false,
	formValues: {},
	selectedFields: {},
	countrie_list: JSON.parse(sessionStorage.getItem('countrie_list') || '[]') || [],
	region_list: JSON.parse(sessionStorage.getItem('region_list') || '[]') || [],
	work_came_back_countrie_list: JSON.parse(sessionStorage.getItem('work_came_back_countrie_list') || '[]') || [],
	trip_purpose_list: [],
	transport_type_list: [],
	// districts_list: JSON.parse(sessionStorage.getItem('districts_list') || '[]') || [],
	districts_list: [],
	response: false,
	deport: {},
};

const reducers_main = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action?.payload,
			};

		case 'SET_USER':
			return {
				...state,
				user: action?.payload,
			};
		case 'SET_AUTH_MODAL':
			return {
				...state,
				auth_modal: action?.payload,
			};
		case 'SET_SEARCH_FORM_VALUE':
			return {
				...state,
				formValues: action?.payload,
			};
		case 'SET_SELECT_FIELDS':
			return {
				...state,
				selectedFields: action?.payload,
			};
		case 'SET_COUNTRUES':
			sessionStorage.setItem('countrie_list', JSON.stringify(action?.payload));
			return {
				...state,
				countrie_list: action?.payload,
			};
		case 'SET_WORK_CAME_BACK_COUNTRUES':
			sessionStorage.setItem('work_came_back_countrie_list', JSON.stringify(action?.payload));
			return {
				...state,
				work_came_back_countrie_list: action?.payload,
			};
		case 'SET_REGIONS':
			sessionStorage.setItem('region_list', JSON.stringify(action?.payload));
			return {
				...state,
				region_list: action?.payload,
			};
		case 'SET_TRIP_PURPOSE_LIST':
			return {
				...state,
				trip_purpose_list: action?.payload,
			};
		case 'SET_TRANSPORT_TYPE_LIST':
			return {
				...state,
				transport_type_list: action?.payload,
			};
		case 'SET_DISTRICTS':
			// sessionStorage.setItem('districts_list', JSON.stringify(action?.payload));
			//   console.log(state?.districts_list, action?.payload);
			return {
				...state,
				districts_list: uniqBy([...state?.districts_list, ...action?.payload], 'value'),
			};
		case 'SET_RESPONSE':
			return {
				...state,
				response: action?.payload,
			};
		case 'SET_DEPORT':
			return {
				...state,
				deport: action?.payload,
			};
		default:
			return state;
	}
};
export default reducers_main;
