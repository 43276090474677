import React from 'react';

const PoliceSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.45136 11.6667C2.22341 12.7707 1.84213 15.4801 4.4213 16.1531C5.95396 16.5529 8.34059 17.1015 9.60209 17.4036C9.86425 17.4664 10.1358 17.4664 10.3979 17.4036C11.6594 17.1015 14.0461 16.5529 15.5787 16.1531C18.1578 15.4801 17.7766 12.7707 16.5487 11.6667'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M8.33325 6.66668L9.99992 5.83334L11.6666 6.66668C11.6666 7.08334 11.6666 8.33334 9.99992 9.16668C8.33325 8.33334 8.33325 7.08334 8.33325 6.66668Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.47708 7.17884C3.75437 6.07747 6.45933 3.97532 10.0001 2.5C13.5408 3.97532 16.2458 6.07747 17.5231 7.17884C18.0557 7.63809 18.3334 8.31176 18.3334 9.015C18.3334 10.4795 17.1462 11.6667 15.6817 11.6667H4.3184C2.85393 11.6667 1.66675 10.4795 1.66675 9.015C1.66675 8.31176 1.94447 7.63809 2.47708 7.17884Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PoliceSidebarIcon;
