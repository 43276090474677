import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';

const reports_route = [
	{
		path: 'reports',
		children: [
			{
				path: 'abroad-citizen',
				handle: {
					breadcrumb: t => {
						return { link: 'abroad-citizen/', title: t('other_t.reports_citizens_abroad') };
					},
					roles: [roles.allow_any],
				},
				children: [
					{
						index: true,
						component: lazy(() => import('../../components/report_pages/ReportAbroadCitizen')),
						handle: {
							title: 'Report Abroad Citizen',
							roles: [roles.allow_any],
						},
					},
					{
						path: 'list',
						component: lazy(() => import('../../components/report_pages/DeportList')),
						handle: {
							title: 'Deport List',
							roles: [roles.allow_any],
						},
					},
				],
			},
			{
				path: 'abroad-backcitizen',
				component: lazy(() => import('../../components/report_pages/ReportAbroadCitizenBack')),
				handle: {
					breadcrumb: t => {
						return { link: 'abroad-backcitizen/', title: t('other_t.report_on_citizens') };
					},
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-minormigrants',
				component: lazy(() => import('../../components/report_pages/ReportsMinormigrants')),
				handle: {
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-bornabroad',
				component: lazy(() => import('../../components/report_pages/ReportsBornAbroad')),
				handle: {
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-guardians',
				component: lazy(() => import('../../components/report_pages/ReportsGuardians')),
				handle: {
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-abroad-persons',
				component: lazy(() => import('../../components/report_pages/ReportAbroadPersons')),
				handle: {
					breadcrumb: t => {
						return { link: 'reports-abroad-persons/', title: t('other_t.report_on_citizens_no') };
					},
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-detained',
				component: lazy(() => import('../../components/report_pages/ReportsDetained')),
				handle: {
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-killedabroad',
				component: lazy(() => import('../../components/report_pages/ReportsKilledAbroad')),
				handle: {
					roles: [roles.allow_any],
				},
			},
			{
				path: 'reports-renounced',
				component: lazy(() => import('../../components/report_pages/ReportsRenounced')),
				handle: {
					roles: [roles.allow_any],
				},
			},
		],
	},
];

export default reports_route;
