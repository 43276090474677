import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import reducers_main from "./reducers_main";
import reducers_geodata from "./reducers_geodata";
import reducers_statistics from "./reducers_statistics";

const storeAS = (initialState) => {
  const middleware = [thunk];
  const rootReducer = combineReducers({
    main: reducers_main,
    geodata: reducers_geodata,
    statistic: reducers_statistics,
  });
  return createStore(rootReducer, initialState, applyMiddleware(...middleware));
};
const store = storeAS();
export default store;
