export const MAIN_PAGE = '/main-statistic/M';

export const reactSelectConfiguration = {
	isSearchable: true,
	isMulti: false,
	styles: {
		option: (provided, { isFocused, isSelected }) => ({
			...provided,
			color: isSelected || isFocused ? '#3164F7' : '#1D2630',
			background: 'white',
			width: '100%',
			cursor: 'pointer',
			opacity: isFocused ? '0.8' : '',
			padding: '8px',
			transition: '400ms',
			fontSize: '16px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		container: provided => ({
			...provided,
			width: '100%',
		}),
		singleValue: provided => ({
			...provided,
			color: 'var(--bold)',
		}),
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			borderRadius: 8,
			border: state?.isSelected || state?.isFocused ? '1px solid #3164F7' : '1px solid #E3E3E3',
			cursor: 'pointer',
			color: '#1D2630',
			height: 44,
			margin: '0',
			fontSize: '16px',
			paddingLeft: '20px',
			overflow: 'hidden',
			WebkitBoxOrient: 'vertical',
			WebkkitLineClamp: 1,
			paddingRight: '20px',
			whiteSpace: 'nowrap',
			boxShadow: '',
			transition: '400ms',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '12px',
			},
			':hover': {
				border: '1px solid #3164F7',
			},
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 999999999,
		}),
		menu: provided => ({
			...provided,
			margin: 0,
			width: '100%',
			padding: 16,
			border: '0!important',
			borderRadius: 8,
			boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
			background: 'white',
			overflow: 'hidden',
			top: '50px',
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			height: 'auto',
			zIndex: 11111111,
			'::-webkit-scrollbar': {
				width: '4px',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-track': {
				background: '#F5F5F5',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#767993',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				// background: '#555',
			},
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		indicatorsContainer: (provided, { isFocused }) => ({
			...provided,
			height: '100%',
			paddingRight: '0px',
		}),
		dropdownIndicator: (provided, { isFocused }) => ({
			...provided,
			color: 'var(--grey)',
			paddingRight: isFocused ? '8px' : '0px',
			paddingLeft: isFocused ? '0px' : '8px',
			transform: isFocused ? 'rotate(180deg)' : '',
			':hover': {
				color: 'var(--grey)',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: '0',
			fontWeight: '400',
			alignContent: 'center',
			fontSize: '16px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		noOptionsMessage: provided => ({
			...provided,
			color: 'var(--bold)',
			width: '100%',
			cursor: 'pointer',
			height: 38,
			padding: '14px 12px',
			textAlign: 'left',
			fontSize: '16px',
		}),
		placeholder: provided => ({
			...provided,
			fontSize: '16px',
			fontWeight: '400',
			color: '#767993',
		}),
	},
};

export const defaultProperties = {
	light: {
		circle: {
			bg: '#5B6B79',
			fill: '#5B6B79',
		},
	},
};
export const default_per_page = 20;

// Продолжительность пребывания data chart
export const pointsChartRegion = [
	{ name: 'Более 90 дней ', y: 14, color: '#5B6B79', count: '4365987' },
	{ name: 'Менее 1 года', y: 22, color: '#4680FF', count: '4365987' },
	{ name: 'Временное 1-5 лет', y: 19, color: '#4CB592', count: '3032337' },
	{
		name: 'Долгосрочное (более 5 лет)',
		y: 18,
		color: '#BF58A2',
		count: '3032337',
	},
	{ name: 'Постоянный', y: 22, color: '#E58A00', count: '3032337' },
];

// Гендерное соотношение
export const pointsChartManWomen = [
	{
		name: 'Мужской',
		y: 63,
		color: '#4680FF',
		count: '4365987',
		countplus: '789',
		name: 'statistics.,',
	},
	{
		name: 'Женский',
		y: 37,
		color: '#E58A00',
		count: '3032337',
		countplus: '541',
	},
];

// Возрастная категория мигрантов
export const age = [
	{
		id: 1,
		name: '0-14',
		start: 0,
		end: 14,
	},
	{
		id: 2,
		name: '15-17',
		start: 15,
		end: 17,
	},
	{
		id: 3,
		name: '18-19',
		start: 18,
		end: 19,
	},
	{
		id: 4,
		name: '20-24',
		start: 20,
		end: 24,
	},
	{
		id: 5,
		name: '25-29',
		start: 25,
		end: 29,
	},
	{
		id: 6,
		name: '30-34',
		start: 30,
		end: 34,
	},
	{
		id: 7,
		name: '35-39',
		start: 35,
		end: 39,
	},
	{
		id: 8,
		name: '40-44',
		start: 40,
		end: 44,
	},
	{
		id: 9,
		name: '45-49',
		start: 45,
		end: 49,
	},
	{
		id: 10,
		name: '50-54',
		start: 50,
		end: 54,
	},
	{
		id: 11,
		name: '55-59',
		start: 55,
		end: 59,
	},
	{
		id: 11,
		name: '60-64',
		start: 60,
		end: 64,
	},
	{
		id: 12,
		name: '65+',
		start: 65,
	},
];
export const genders_data = [
	{ value: 1, label: 'Мужчина' },
	{ value: 2, label: 'Женщина' },
];
