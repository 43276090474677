import React, { Suspense } from "react";
import ContentSpinner from "../sections/utilsSections/ContentSpinner";
import Breadcrumb2 from "../sections/uiComponents/Breadcrumb2";

// const NoAccess = () => <div className="bg-red-400">No Access</div>;
const PageProvider = ({ children, breadcrumbs = [] }) => {
  return (
    <Suspense fallback={<ContentSpinner />}>
      <Breadcrumb2 breadcrumbs={breadcrumbs} />
      {children}
    </Suspense>
  );
};

export default PageProvider;
