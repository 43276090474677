import React from 'react';

const AccessSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.33325 8.75001V7.50001C8.33325 6.57954 9.07942 5.83334 9.99992 5.83334C10.9204 5.83334 11.6666 6.57954 11.6666 7.50001V8.75001M9.37492 13.3333H10.6249C11.6023 13.3333 12.0909 13.3333 12.4275 13.075C12.5142 13.0085 12.5918 12.9309 12.6583 12.8443C12.9166 12.5077 12.9166 12.019 12.9166 11.0417C12.9166 10.0643 12.9166 9.57568 12.6583 9.23909C12.5918 9.15243 12.5142 9.07484 12.4275 9.00834C12.0909 8.75001 11.6023 8.75001 10.6249 8.75001H9.37492C8.39759 8.75001 7.90894 8.75001 7.5723 9.00834C7.48564 9.07484 7.40806 9.15243 7.34156 9.23909C7.08325 9.57568 7.08325 10.0643 7.08325 11.0417C7.08325 12.019 7.08325 12.5077 7.34156 12.8443C7.40806 12.9309 7.48564 13.0085 7.5723 13.075C7.90894 13.3333 8.39759 13.3333 9.37492 13.3333Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 9.31941V6.90023C17.5 5.53356 17.5 4.85022 17.1632 4.4044C16.8265 3.95856 16.0651 3.74212 14.5423 3.30924C13.5018 3.01349 12.5847 2.65718 11.8519 2.3319C10.8528 1.88841 10.3533 1.66666 10 1.66666C9.64667 1.66666 9.14717 1.88841 8.14809 2.3319C7.41532 2.65718 6.4982 3.01348 5.45778 3.30924C3.93494 3.74212 3.17352 3.95856 2.83676 4.4044C2.5 4.85022 2.5 5.53356 2.5 6.90023V9.31941C2.5 14.0071 6.71897 16.8196 8.82833 17.9328C9.33425 18.1998 9.58717 18.3333 10 18.3333C10.4128 18.3333 10.6657 18.1998 11.1717 17.9328C13.281 16.8196 17.5 14.0071 17.5 9.31941Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default AccessSidebarIcon;
