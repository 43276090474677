import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import { useMemo } from "react";
import PageProvider from "./PageProvider";

const generateRouter = (route, parent_breadcrumb) => {
  return route.map((item) => {
    const PageComponent = item.component;
    const breadcrumbs = [
      ...parent_breadcrumb,
      ...(item?.handle?.breadcrumb ? [item.handle.breadcrumb] : []),
    ];

    let newItem = { ...item };
    if (PageComponent) {
      newItem.element = (
        <PageProvider breadcrumbs={breadcrumbs}>
          <PageComponent handle={item.handle} />
        </PageProvider>
      );
      delete newItem.component;
    }
    if (Array.isArray(newItem.children)) {
      newItem.children = generateRouter(newItem.children, breadcrumbs);
    }
    return newItem;
  });
};

const RouteProvider = () => {
  const main_routes = useMemo(() => generateRouter(routes, []), [routes]);

  const router = useMemo(() => createBrowserRouter(main_routes), [main_routes]);

  return <RouterProvider router={router} />;
};

export default RouteProvider;
