import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';

const migrant_route = [
  {
    path: 'migrant/:id',
    handle: {
      roles: [roles.allow_any],
      breadcrumb: (t, params) => {
        return {
          link: `migrant/${params?.id}/`,
          title: t('biographicallInfo.more-info'),
        };
      },
    },
    children: [
      {
        index: true,
        component: lazy(() =>
          import('../../components/user_information/FullInfo')
        ),
        handle: {
          roles: [roles.allow_any],
        },
      },
      {
        path: 'filter-question',
        component: lazy(() =>
          import('../../components/user_information/FullInfo')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `filter-question/`,
              title: t('inspectors.biographical-data'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'biografi',
        component: lazy(() =>
          import('../../components/user_information/BiografiUser')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `biografi/`,
              title: t('inspectors.biographical-data'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'adress',
        component: lazy(() =>
          import('../../components/user_information/CurrentAdress')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `adress/`,
              title: t('actualPlace.actual-place'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'actual',
        component: lazy(() =>
          import('../../components/user_information/ActualPlace')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `actual/`,
              title: t('actualPlace.actual_location'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'digital-neighborhood',
        component: lazy(() =>
          import('../../components/user_information/FullDigitalNeighborhood')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `digital-neighborhood/`,
              title: t('search_content.digital_neighborhood'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'inspector-anketa',
        component: lazy(() =>
          import('../../components/user_information/InspectorAnketaForFullInfo')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `inspector-anketa/`,
              title: t('other_t.questionnaire'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'education',
        component: lazy(() =>
          import('../../components/user_information/Education')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `education/`,
              title: t('currentEducational.education-name'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'social_status',
        component: lazy(() =>
          import('../../sections/userInfoSections/AdditionalInformation')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `social_status/`,
              title: t('userInfo.andiotional'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'work',
        component: lazy(() =>
          import('../../components/user_information/PlaseWork')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `work/`,
              title: t('search_content.work'),
            };
          },
          roles: [roles.allow_any],
        },
      },
      {
        path: 'filter_questions',
        component: lazy(() =>
          import('../../sections/userInfoSections/FilterQuestions')
        ),
        handle: {
          breadcrumb: (t) => {
            return {
              link: `filter_questions/`,
              title: t('home.filter_q'),
            };
          },
          roles: [roles.allow_any],
        },
      },
    ],
  },
];

export default migrant_route;
