import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_URL } from '../../utils/api';
import { capitalizeFirstLetter, capitalizeWords } from '../../utils/functions';
import Axios from '../../utils/httpClient';
import { getToken } from '../../utils/tokenStorge';
import Header from './Header';
import SideBar from './SideBar';

const Layout = ({ children }) => {
	const token = getToken();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const { user, response } = useSelector(s => s.main);
	const [openSection, setOpenSection] = useState(null);

	useEffect(() => {
		if (!user?.id) getUser();
		if (!token) navigate('/login');
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	useEffect(() => {
		getCountries();
		getCountriesWorkCameBack();
		getRegions(182);
		getTripPurposeList();
		getTransportTypeCodeList();
	}, [i18n.language]);
	const getUser = () => {
		Axios(AUTH_URL)
			.get(`/account/me/`)
			.then(r => {
				dispatch({ type: 'SET_USER', payload: r?.data ?? {} });
			})
			.catch(e => {})
			.finally(() => {});
	};
	const handleResize = () => {
		if (window.matchMedia('(max-width: 768px)').matches) {
			dispatch({ type: 'SET_RESPONSE', payload: true });
		} else {
			dispatch({ type: 'SET_RESPONSE', payload: false });
		}
	};
	const getCountries = () => {
		Axios()
			.get(`/guide/countries/`)
			.then(res => {
				let tt = [];
				res?.data?.forEach(element => {
					tt.push({
						value: String(element?.id),
						flag: element?.flag,
						iso_a3: element?.code || '',
						label: capitalizeWords(element?.name),
					});
				});
				dispatch({ type: 'SET_COUNTRUES', payload: tt || [] });
			});
	};
	const getCountriesWorkCameBack = () => {
		Axios()
			.get(`/migration/work-came-back-country/?page_size=254`)
			.then(res => {
				let tt = [];
				res?.data?.results?.forEach(element => {
					tt.push({
						value: String(element?.id),
						country: String(element?.country?.id),
						flag: element?.country?.flag,
						iso_a3: element?.country?.code || '',
						label: capitalizeWords(element?.country?.name),
					});
				});
				dispatch({ type: 'SET_WORK_CAME_BACK_COUNTRUES', payload: tt || [] });
			});
	};
	const getRegions = id => {
		Axios()
			.get(`/guide/regions/?country=${id}`)
			.then(res => {
				let tt = [];
				res?.data?.forEach(element => {
					tt.push({
						value: String(element?.id),
						label: capitalizeFirstLetter(element?.name),
						soato: String(element?.soato_code),
					});
					getDistricts(element?.id);
				});
				dispatch({ type: 'SET_REGIONS', payload: tt || [] });
			});
	};
	const getDistricts = r_id => {
		Axios()
			.get(`/guide/districts/?region=${r_id}`)
			.then(res => {
				let tt = [];
				(res?.data).forEach(element => {
					tt.push({
						value: String(element?.id),
						label: capitalizeFirstLetter(element?.name),
						region: String(element?.region),
						soato: String(element?.soato_code),
					});
					dispatch({ type: 'SET_DISTRICTS', payload: tt || [] });
				});
			});
	};
	const getTripPurposeList = () => {
		Axios()
			.get(`guide/travel-purpose-guide/`)
			.then(res => {
				dispatch({ type: 'SET_TRIP_PURPOSE_LIST', payload: res?.data || [] });
			})
			.catch(err => {})
			.finally(() => {});
	};
	const getTransportTypeCodeList = () => {
		Axios()
			.get(`guide/transport/`)
			.then(res => {
				dispatch({ type: 'SET_TRANSPORT_TYPE_LIST', payload: res?.data || [] });
			})
			.catch(err => {})
			.finally(() => {});
	};
	return (
		<div className='container2 relative flex w-full'>
			<div className={`fixed top-0 min-h-screen z-[10000] ${response ? 'w-[65px]' : 'w-[270px]'} duration-250 transition-all ease-linear`}>
				<SideBar setOpenSection={setOpenSection} openSection={openSection} />
			</div>
			<div className={`overflow-hidden ${response ? 'ml-[65px] ' : 'ml-[270px] '} w-full min-h-screen relative duration-250 transition-all ease-linear`}>
				<div className=''>
					<Header />
				</div>
				<div className=' sm:p-[20px] p-[10px] bg-alabaster !pb-[100px] mt-[55px] min-h-[90vh]'>{children}</div>
			</div>
		</div>
	);
};

export default Layout;
