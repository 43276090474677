import React from 'react';

const AgeeSidebarIcon = ({ fill }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0001 18.3334C14.6025 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6025 1.66669 10.0001 1.66669C5.39771 1.66669 1.66675 5.39765 1.66675 10C1.66675 14.6024 5.39771 18.3334 10.0001 18.3334Z'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0834 13.3333C11.4867 13.8598 10.7704 14.1667 10.0001 14.1667C9.22975 14.1667 8.5135 13.8598 7.91675 13.3333'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 9.58332C7.14197 9.32007 6.71218 9.16666 6.25 9.16666C5.78782 9.16666 5.35803 9.32007 5 9.58332'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 9.58332C14.642 9.32007 14.2122 9.16666 13.75 9.16666C13.2878 9.16666 12.858 9.32007 12.5 9.58332'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 1.66669C8.61925 1.66669 7.5 2.78598 7.5 4.16669C7.5 5.5474 8.61925 6.66669 10 6.66669C10.6403 6.66669 11.2243 6.42598 11.6667 6.03011'
        stroke={fill ? fill : '#5B6B79'}
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AgeeSidebarIcon;
