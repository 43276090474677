import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';
import migrant_route from './migrant_route';

const inspectors_route = [
	{
		path: 'inspectors',
		handle: {
			roles: [roles.allow_any],
			breadcrumb: t => {
				return {
					link: `inspectors/`,
					title: t('sidebar.inspectors'),
				};
			},
		},
		children: [
			{
				index: true,
				component: lazy(() => import('../../components/inspectors/InspectorsList')),
			},
			{
				path: ':inspectorId/:slug/:directionTypeCode',
				handle: {
					roles: [roles.allow_any],
					breadcrumb: (t, params) => {
						return {
							link: `${params?.inspectorId}/${params?.slug}/`,
							title: t('inspectors.information-inspector'),
						};
					},
				},
				children: [
					{
						index: true,
						component: lazy(() => import('../../components/inspectors/InspectorsItem')),
					},
					...migrant_route,
				],
			},
			{
				path: 'survey/migrants/:childId/:directionTypeCode',
				handle: {
					roles: [roles.allow_any],
					breadcrumb: (t, params) => {
						return {
							link: `survey/migrants/${params?.childId}/${params?.directionTypeCode}/`,
							title: params?.directionTypeCode === 'P' ? t('inspector_count.text5') : t('inspector_count.text6'),
						};
					},
				},
				children: [
					{
						index: true,
						component: lazy(() => import('../../components/inspectors/SurveyMigrants')),
					},
					...migrant_route,
				],
			},
		],
	},
];

export default inspectors_route;
