import React from "react";
import { Provider } from "react-redux";
import store from "./utils/reducers/store";
import WebProvider from "./utils/WebProvider";
import RouteProvider from "./routers/RouteProvider";
import { useTokenRefresh } from "./utils/hooks/useTokenRefresh";
import ContentSpinner from "./sections/utilsSections/ContentSpinner";

const App = () => {
  const { isRefreshing } = useTokenRefresh();
  return (
    <Provider store={store}>
      {isRefreshing ? (
        <ContentSpinner />
      ) : (
        <WebProvider>
          <RouteProvider />
        </WebProvider>
      )}
    </Provider>
  );
};
export default App;
