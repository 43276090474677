import { useState, useEffect } from "react";
import Axios from "../httpClient";
import { removeToken, setToken } from "../tokenStorge";
import { AUTH_URL } from "../api";

export const useTokenRefresh = () => {
  const [isRefreshing, setIsRefreshing] = useState(true);
  const getTokenData = () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refresh");
    return { token, refreshToken };
  };
  const decodeJWT = (token) => {
    if (!token) return null;
    const [, payload] = token.split(".");
    try {
      return JSON.parse(atob(payload));
    } catch (e) {
      console.error("Failed to decode token:", e);
      return null;
    }
  };
  const isTokenExpiringSoon = (token) => {
    if (token) {
      const payload = decodeJWT(token);
      if (!payload || !payload.exp) return false;
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = payload.exp;
      const timeLeft = expirationTime - currentTime;
      return timeLeft <= 20 * 60; // Check if less than or equal to 20 minutes
    } else {
      return true;
    }
  };
  const refreshAuthToken = (refreshToken, is_loading = false) => {
    setIsRefreshing(is_loading);
    Axios(AUTH_URL)
      .post("account/me/refresh/", { refresh: refreshToken })
      .then((r) => {
        setToken(r?.data?.access);
      })
      .catch((e) => {
        removeToken();
        localStorage.removeItem("refresh");
        console.error("Error while refreshing token:", e);
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };
  const checkToken = (is_loading = false) => {
    const { token, refreshToken } = getTokenData();
    if (refreshToken) {
      if (isTokenExpiringSoon(token)) {
        refreshAuthToken(refreshToken, is_loading);
      } else {
        setIsRefreshing(false);
      }
    } else {
      console.warn("Refresh token not found.");
      setIsRefreshing(false);
    }
  };
  useEffect(() => {
    checkToken(true);
    const interval = setInterval(async () => {
      checkToken();
    }, 0.5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(interval);
  }, []);

  return { isRefreshing };
};
