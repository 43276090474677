import { lazy } from 'react';
import { roles } from '../../utils/roleManager/roles';
import migrant_route from './migrant_route';

const minors_route = [
  {
    path: 'minors/:childId/:directionTypeCode',
    // handle: {
    //   roles: [roles.allow_any],
    //   breadcrumb: (t, params) => {
    //     return {
    //       link: `minors/${params?.childId}/${params?.directionTypeCode}`,
    //       title: t('sidebar.agee'),
    //     };
    //   },
    // },
    children: [
      {
        path: 'main',
        handle: {
          roles: [roles.allow_any],
          breadcrumb: (t, params) => ({
            link: `minors/${params?.childId}/${params?.directionTypeCode}/main/`,
            title: t('other_t.foreign_citizens_minors'),
          }),
        },
        children: [
          {
            index: true,
            component: lazy(() =>
              import('../../components/minors_pages/MinorList')
            ),
          },
          ...migrant_route,
        ],
      },
      {
        path: 'unaccompanied',
        handle: {
          roles: [roles.allow_any],
          breadcrumb: (t, params) => ({
            link: `minors/${params?.childId}/${params?.directionTypeCode}/unaccompanied/`,
            title: t('other_t.unaccompanied_minors'),
          }),
        },
        children: [
          {
            index: true,
            component: lazy(() =>
              import('../../components/minors_pages/MinorUnaccompaniedList')
            ),
          },
          ...migrant_route,
        ],
      },
      {
        path: 'is-has-unaccompanied',
        handle: {
          roles: [roles.allow_any],
          breadcrumb: (t, params) => ({
            link: `minors/${params?.childId}/${params?.directionTypeCode}/is-has-unaccompanied/`,
            title: t('sidebar.is_has_unaccompanied'),
          }),
        },
        children: [
          {
            index: true,
            component: lazy(() =>
              import(
                '../../components/minors_pages/MinorIsHasUnaccompaniedList'
              )
            ),
          },
          ...migrant_route,
        ],
      },
    ],
  },
];

export default minors_route;
